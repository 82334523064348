import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import CardGeneral from "./card-general";
import { Check, CheckCircle } from "react-feather";
import TTPro from  "./sidebars/ttpro.svg"
import Button from "./atoms/Button";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { navigate } from "gatsby";
import { BadgeCheck, ListOrdered, Smile, Sparkles, Users2 } from "lucide-react";

const WhyUs = () => {
  const [claimingInProgress, setClaimingInProgress] = useState(false);

  const [offer, setOffer] = useLocalStorage("offers", {});

  const claimOffer = () => {
    setOffer({ offer: "$50 OFF Tesla Wall Charger Installation", expires: "" });
    setClaimingInProgress(true);
    setTimeout(() => {
      navigate("/contact/");
    }, 1000);
  };

  return (
    <StaticQuery
      query={graphql`
        query WhyUsListQuery {
          site {
            siteMetadata {
              whyUs
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
       
            <div className="rounded-xl mb-2 shadow bg-white ">
              <div class="px-8 pt-8 pb-6 border-b">
                <h4 class="text-gray-900 font-bold text-xl md:text-2xl font-display">Why US?</h4>
                <p class="text-gray-600 font-normal text-sm md:text-base font-display">Highly rated plumbing experts</p>
              </div>
              <ul className="pb-10 pt-6 px-8 space-y-6 text-gray-600">
                <li className="flex items-start">
                  {" "}
                  <ListOrdered size={24}   className="text-brand-800 shrink-0" />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">Free Quotes, Competitive Pricing</span>
                    <p>Comprehensive, straightforward in-home estimates and pricing</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Users2 size={24}    className="text-brand-800 shrink-0" />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">Durable Products that Last</span>

                    <p>Manufacturer warranties on parts, generous warranties on service.</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Sparkles size={24} className="text-brand-800 shrink-0" />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">Local, Family-Owned & Operated</span>

                    <p>Decades of unparalleled expertise</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <BadgeCheck size={24} className="text-brand-800 shrink-0" />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">Licensed Plumbing Pros</span>

                    <p>Authorized, licensed, certified, and insured—so you'll never have to worry!</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Smile size={24}    className="text-brand-800 shrink-0" />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-gray-800 block">Old-school Professionalism</span>

                    <p>Detailed cleanup and dependable follow-through</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="rounded-xl mb-2 shadow bg-white">
              <div class="px-8 py-6 flex items-start space-x-4">
               
                <div>
                  <div className="flex items-center">
               
                <div className="flex-1">
                  <h4 class="text-gray-900 font-bold text-lg md:text-xl font-display">Meet the Owner</h4>
                  <h4 class="text-gray-600 font-medium text-base md:text-lg font-display">Roman K.</h4></div>

                  <img
                  src="https://s3-media0.fl.yelpcdn.com/buphoto/_nMpYB-iMsQiBKPXpVtgCQ/180s.jpg"
                  className="w-20 h-20 rounded-full border-2 border-white"
                />
                  </div>

                  <p className="text-gray-500 font-display font-normal text-sm">
                  Roman Kovalchuk began his career in the plumbing industry in New Jersey, where he gained extensive experience in a variety of projects, including water heaters replacement, radiant heating, boiler replacements and hydronics. After honing his skills in the east coast, Roman decided to expand his business and make a move to California. His company West Coast Hydronics & Plumbing is known for providing top-quality workmanship and excellent customer service. With Roman's expertise and dedication, his business has become a reputable and trusted choice for plumbing and heating services in the area.
                  </p>
                  <Button className="bg-blue-600 mt-4" to="/contact/">
                    Get a Quote
                  </Button>
                </div>
              </div>
            </div>
          
            <div className="rounded-xl mb-2 shadow bg-white">
              <div class="px-8 py-6 flex items-start space-x-4">
                {/* <img
                  src="https://images.ctfassets.net/seqn6hyrh7p7/1YBBOd4h58mVjDFPWklph0/0b0634904698a95f1b53f337ce548401/Screen_Shot_2022-08-04_at_12.48.30_PM.png?h=250"
                  className="w-16 h-16 rounded-full border-2 border-white"
                /> */}
                <div>
                  <h4 class="text-gray-900 font-bold text-lg md:text-xl font-display">Talk to our experts</h4>
                  <p className="text-gray-500 font-display font-normal">
                    Free estimates, clear straightforward pricing
                  </p>
                  <Button className="bg-blue-600 mt-4" to="/contact/">
                    Get Pricing
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="rounded-xl mb-2 shadow bg-white mt-4 border-4 border-brand-100">
              <div class="px-8 py-6 flex items-center space-x-4">
                <img
                  src=" https://images.ctfassets.net/seqn6hyrh7p7/KhtI1D3NxmJaQZ1SdiXul/e041fdecc73b85da180569819df93db5/kindpng_4658234.png"
                  className="w-1/3"
                />
                <div>
                  <h3 class="text-brand-800 font-bold text-xl md:text-3xl font-display">$50 OFF</h3>
                  <p className="text-brand-800 font-display font-semibold text-lg ">Tesla Wall Charger Installation</p>
                
                  <Button className="mt-4" onClick={() => claimOffer()}>
                    {claimingInProgress ? (
                      <svg
                        class="animate-spin ml-3 mr-3 h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Claim"
                    )}
                  </Button>
                </div>
              </div>
            </div> */}
          </>
        );
      }}
    />
  );
};

export default WhyUs;
